<template>
  <div>
    <div class="home-banner">
      <video
        v-show="playVideoTag"
        ref="video1"
        id="video1"
        preload="auto"
        poster="@/assets/images/backgroud.jpg"
        webkit-playsinline
        playsinline
        loop="loop"
        autoplay="autoplay"
        muted="muted"
        volume="0"
        type="video/mp4"
        :src="videoList[0]"
      ></video>
      <!-- <video
        v-show="!playVideoTag"
        ref="video2"
        id="video2"
        @ended="onEnded(2)"
        preload="auto"
        poster="@/assets/images/backgroud.jpg"
        webkit-playsinline
        playsinline
        autoplay="autoplay"
        muted="muted"
        volume="0"
        type="video/mp4"
        :src="videoList[1]"
      ></video> -->
      <div class="home-slogan">
        {{
          homeInfo.by ? homeInfo.by.split(",")[0] : "感知新世界"
        }}&nbsp;&nbsp;&nbsp;&nbsp;{{
          homeInfo.by ? homeInfo.by.split(",")[1] : "共筑智未来"
        }}
      </div>
      <div class="block">
        <div class="item" v-up="'queue-bottom'">
          <span class="txt">单线激光雷达</span>
          <span class="subtxt" @click="bindSeries(0)">
            <span v-for="(item, index) in danPro" :key="item.name">{{
              item.name + (index == danPro.length - 1 ? "" : "/")
            }}</span>
            <img src="@/assets/images/arrow.png" alt="箭头" />
          </span>
          <span class="line">
            <span ref="progress1" class="progress"></span>
          </span>
        </div>
        <div class="item" v-up="'queue-bottom'">
          <span class="txt">多线激光雷达</span>
          <span class="subtxt" @click="bindSeries(1)">
            <span v-for="(item, index) in duoPro" :key="item.name">{{
              item.name + (index == duoPro.length - 1 ? "" : "/")
            }}</span>
            <img src="@/assets/images/arrow.png" alt="箭头" />
          </span>
          <span class="line">
            <span ref="progress2" class="progress"></span>
          </span>
        </div>
      </div>
    </div>
    <!-- 数字展示 -->
    <div class="home-session">
      <div class="home-pack home-digit2">
        <div
          class="item"
          v-for="item in digitList"
          :key="item.id"
          :style="{
            'background-image': 'url(' + item.image + ')',
            'background-size': '100% 100%',
          }"
        >
          <div class="digit2">
            <span v-change="item.num">{{ item.num ? item.num : "" }}</span>
            <span class="unit2">{{ item.unit ? item.unit : "" }}</span>
          </div>
          <div class="label2">{{ item.content ? item.content : "" }}</div>
        </div>
      </div>
    </div>
    <!-- 首页产品展示 -->
    <div class="home-box1">
      <div class="inner">
        <div class="block-one" v-for="item in danPro" :key="item._id">
          <img :src="item.syhf" loading="lazy" :alt="item.name + '系列'" />
          <div class="pro-info" v-up="'queue-bottom'">
            <div class="topic">{{ item.light }}</div>
            <div class="title">
              <span>{{ item.name }}</span
              >系列
              <div v-if="item.new" class="tag">新品</div>
            </div>
            <div class="pro-more">
              <div class="jump">
                <span @click="readMore(1, item.name)">了解更多</span>
                <img src="@/assets/images/arrow2.png" alt="箭头图标" />
              </div>
              <RouterLink to="/contact" class="jump">
                <span>咨询问价</span>
                <img src="@/assets/images/arrow2.png" alt="箭头" />
              </RouterLink>
              <!-- <a
                class="jump"
                href="https://shop218101118.taobao.com/?spm=a230r.7195193.1997079397.2.2f0a472d1jmZ1t#/"
                target="_blank"
              >
                <span>立即购买</span>
                <img src="@/assets/images/arrow2.png" alt="箭头" />
              </a> -->
            </div>
          </div>
        </div>
        <div class="block-one" v-for="item in duoPro" :key="item._id">
          <img :src="item.syhf" loading="lazy" :alt="item.name + '系列'" />
          <div class="pro-info" v-up="'queue-bottom'">
            <div class="topic">{{ item.light }}</div>
            <div class="title">
              <span>{{ item.name }}</span
              >系列
              <div v-if="item.new" class="tag">新品</div>
            </div>
            <div class="pro-more">
              <div class="jump">
                <span @click="readMore(2, item.name)">了解更多</span>
                <img src="@/assets/images/arrow2.png" alt="箭头" />
              </div>
              <RouterLink to="/contact" class="jump">
                <span>咨询问价</span>
                <img src="@/assets/images/arrow2.png" alt="箭头" />
              </RouterLink>
              <!-- <a
                class="jump"
                href="https://shop218101118.taobao.com/?spm=a230r.7195193.1997079397.2.2f0a472d1jmZ1t#/"
                target="_blank"
              >
                <span>立即购买</span>
                <img src="@/assets/images/arrow2.png" alt="箭头" />
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案——走马灯 -->
    <div class="home-title">解决方案</div>
    <div class="swiper-box1 long-swiper" v-if="swiperFlag">
      <swiper :options="swiperOption" class="home-swiper swiper" @click="handleClick">
        <!-- :data-path="item.lydz" -->
        <swiper-slide class="item" v-for="item in soluList" :key="item.name">
          <div class="words">
            <div class="title">{{ item.name }}</div>
            <div class="more swipmore" :data-path="item.lydz">
              <span>了解更多</span>
              <img src="@/assets/images/arrow.png" alt="箭头" />
            </div>
          </div>
          <img class="cation" :src="item.syhf" loading="lazy" :alt="item.name" />
        </swiper-slide>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- 上一张按钮 -->
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <!-- 下一张按钮 -->
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
    </div>
    <div class="swiper-box1 short-swiper" v-if="swiperFlag">
      <swiper :options="swiperOption" class="home-swiper" @click="handleClick">
        <swiper-slide class="item" v-for="item in soluList" :key="item.name">
          <div class="words">
            <div class="title">{{ item.name }}</div>
            <div class="more swipmore" :data-path="item.lydz">
              <span>了解更多</span>
              <img src="@/assets/images/arrow.png" alt="箭头" />
            </div>
          </div>
          <img class="cation" :src="item.syhf" loading="lazy" :alt="item.name" />
        </swiper-slide>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- 上一张按钮 -->
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <!-- 下一张按钮 -->
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
    </div>

    <!-- 成功案例 -->
    <div class="home-title">成功案例</div>
    <div class="home-session">
      <div class="home-pack success-story">
        <div class="success-line1">
          <div
            :class="['item', 'item' + (index + 1)]"
            v-for="(item, index) in sucessLine.slice(0, 4)"
            :key="item._id"
          >
            <img :src="item.pt" :alt="item.title" class="img" />
            <span class="tip">{{ item.title }}</span>
          </div>
        </div>
        <div class="success-line2">
          <div
            :class="['item', 'item' + (index + 1)]"
            v-for="(item, index) in sucessLine.slice(4)"
            :key="item._id"
          >
            <img :src="item.pt" :alt="item.title" class="img" />
            <span class="tip">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 为什么选择我们 -->
    <p class="why-title">为什么选择我们？</p>
    <div class="home-session why-session">
      <div class="home-pack">
        <div class="why-content">
          <div class="item" v-for="item in whySelect" :key="item._id">
            <img :src="item.tb" :alt="item.title" class="img" />
            <div class="inner">
              <p class="why-subtitle" v-up="'queue-bottom'">{{ item.title }}</p>
              <p class="why-pass" v-up="'queue-bottom'">{{ item.zw }}</p>
            </div>
          </div>
        </div>
        <div class="quote">
          <div class="mailto" @click="getQuote">获取报价</div>
          <!-- <a class="mailto" href="mailto:sale@richbeam.com">获取报价</a> -->
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="home-title">合作伙伴</div>
    <div class="home-session">
      <div class="home-pack home-partner">
        <div class="partner">
          <div class="item" v-for="(item, index) in partList" :key="index">
            <img :src="item" loading="lazy" alt="合作伙伴" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { getSeleData, getAllData } from "@/utils/http.js";
// let vm = null
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState([
      "homeContent",
      "homeVideo",
      "homeDigit",
      "homeDanList",
      "homeDuoList",
      "homeSolution",
      "homePartList",
      "homeWhyList",
      "homeSuccess",
    ]),
  },
  data() {
    return {
      videoList: [],
      playVideoTag: true,
      proOption: {},
      swiperFlag: false,
      swiperOption: {
        //左右点击
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        observer: true,
        observeParents: true,
        initialSlide: 0,
        loop: true,
        loopAdditionalSlides: 1,
        grabCursor: true,
        // 分页器
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
        },
      },
      digitList: [
        {
          id: "",
          num: "",
          unit: "",
          content: "",
          image: "",
        },
        {
          id: "",
          num: "",
          unit: "",
          content: "",
          image: "",
        },
        {
          id: "",
          num: "",
          unit: "",
          content: "",
          image: "",
        },
      ],
      homeInfo: [],
      productList: [],
      soluList: [],
      partList: [],
      danPro: [],
      duoPro: [],
      tempDan: [],
      whySelect: [],
      sucessLine: [],
    };
  },
  directives: {},
  methods: {
    handleClick(event) {
      let more = event.target.closest(".swipmore");
      if (more) {
        let path = more.dataset.path;
        this.bindSolution(path);
      }
    },
    // onEnded(val) {
    //   let that = this;
    //   // 切换可显示的video
    //   that.playVideoTag = !that.playVideoTag;
    //   // 第一个播放完播放第二个，第二个播放完再播放第一个
    //   if (val == 1) {
    //     that.$refs.video2.play();
    //   } else {
    //     that.$refs.video1.play();
    //   }
    // },
    // playVideo() {
    //   // 第一个video开始播放,添加执行动画
    //   this.$refs.video1.play();
    //   // 预加载第二个视频，暂停第二个视频播放
    //   this.$refs.video2.addEventListener(
    //     "canplay",
    //     (e) => {
    //       e.target.pause();
    //     },
    //     { once: true }
    //   );
    // },
    readMore(type, name) {
      let newName = name.replace(/\s*/g, "");
      if (type == 1) {
        this.$router.push(`/product/${newName}`);
      } else {
        this.$router.push(`/multiline/${newName}`);
      }
    },
    bindSolution(val) {
      this.$router.push(`/solution/${val}`);
    },
    bindSeries(val) {
      this.$router.push({ name: "series", path: "/series", params: { type: val } });
    },
    getQuote() {
      this.$alert(
        '<img style="width: 150px; height: 150px;text-align: center;" src="' +
          require("@/assets/images/kefu.jpg") +
          '" alt="微信">',
          "扫一扫下方二维码，获取报价详情",
        {
          dangerouslyUseHTMLString: true,
          center: true,
          closeOnClickModal: true,
          callback: () => {
          },
        }
      );
    },
    // 获取首页信息
    async getHomeInfo() {
      let that = this;
      if (Object.keys(that.homeContent).length == 0) {
        let res = await getAllData("webhome", 100);
        that.homeInfo = res[0];
        that.$store.commit("saveHomeContent", res[0]);
        that.getRealVideo(res[0].bjsp);
        that.runConcurrently([
          that.getPartList(res[0].hzhb),
          // (that.partList = await that.getFileURL(res[0].hzhb)),
          that.getDitgitInfo(res[0].digit, res[0].szzspt),
          that.getDanSeries(res[0].dan),
          that.getDuoSeries(res[0].duo),
          that.getSolution(res[0].jjfa),
        ]);
      } else {
        that.homeInfo = that.homeContent;
        that.getRealVideo(that.homeContent.bjsp);
        that.runConcurrently([
          that.getPartList(that.homeContent.hzhb),
          // (that.partList = await that.getFileURL(that.homeContent.hzhb)),
          that.getDitgitInfo(that.homeContent.digit, that.homeContent.szzspt),
          that.getDanSeries(that.homeContent.dan),
          that.getDuoSeries(that.homeContent.duo),
          that.getSolution(that.homeContent.jjfa),
        ]);
      }
    },
    // 获取视频
    async getRealVideo(array) {
      let that = this;
      if (that.homeVideo.length == 0) {
        let videoArray = [];
        for (let item of array) {
          await that.getFileURL([item]).then((res) => {
            videoArray.push(res[0]);
          });
        }
        that.videoList = videoArray;
        that.$store.commit("saveHomeVideo", videoArray);
      } else {
        that.videoList = that.homeVideo;
      }
    },
    // 获取数字展示
    async getDitgitInfo(array, array2) {
      let that = this;
      if (that.homeDigit.length == 0) {
        array.forEach(async (item, index) => {
          that.digitList[index].id = index;
          // 中文
          let mark = item.indexOf(":");
          that.digitList[index].content = item.substring(0, mark);
          // 数字
          that.digitList[index].num = item
            .substring(mark + 1, item.length)
            .match(/\d*/)[0];
          // 单位
          that.digitList[index].unit = item
            .substring(mark + 1, item.length)
            .match(/[^\d]+/)[0];
          await that.getFileURL([array2[index]]).then((res) => {
            that.digitList[index].image = res[0];
          });
        });
        that.$store.commit("saveHomeDigit", that.digitList);
      } else {
        that.digitList = that.homeDigit;
      }
    },
    async getDanSeries(array) {
      let that = this;
      if (that.homeDanList.length == 0) {
        let reault = [];
        for (let item of array) {
          let danRes = await getSeleData(
            "danproducts",
            { syhf: true, light: true, new: true, name: true },
            { _id: item },
            60
          );
          if (danRes && danRes.length > 0) {
            await that.getFileURL([danRes[0].syhf]).then((res) => {
              danRes[0].syhf = res[0];
            });
            reault.push(danRes[0]); // 直接推入数组
          }
        }
        that.danPro = reault;
        that.$store.commit("saveHomeDanList", reault);
      } else {
        that.danPro = that.homeDanList;
      }
    },
    async getDuoSeries(array) {
      let that = this;
      if (that.homeDuoList.length == 0) {
        let reault = [];
        for (let item of array) {
          let danRes = await getSeleData(
            "duoproducts",
            { syhf: true, light: true, new: true, name: true },
            { _id: item },
            60
          );
          if (danRes && danRes.length > 0) {
            await that.getFileURL([danRes[0].syhf]).then((res) => {
              danRes[0].syhf = res[0];
            });
            reault.push(danRes[0]); // 直接推入数组
          }
        }
        that.duoPro = reault;
        that.$store.commit("saveHomeDuoList", reault);
      } else {
        that.duoPro = that.homeDuoList;
      }
    },
    async getSolution(array) {
      let that = this;
      if (that.homeSolution.length == 0) {
        let reault = [];
        for (let item of array) {
          let danRes = await getSeleData(
            "jjfa",
            { syhf: true, lydz: true, name: true },
            { _id: item },
            60
          );
          if (danRes && danRes.length > 0) {
            await that.getFileURL([danRes[0].syhf]).then((res) => {
              danRes[0].syhf = res[0];
            });
            reault.push(danRes[0]); // 直接推入数组
          }
        }
        that.soluList = reault;
        that.$store.commit("saveHomeSolution", reault);
        that.swiperFlag = true;
      } else {
        that.soluList = that.homeSolution;
        that.swiperFlag = true;
      }
    },
    async getSuccessStory() {
      let that = this;
      if (that.homeSuccess.length == 0) {
        let res = await getAllData("cgal", 100);
        for (let item of res) {
          await that.getFileURL([item.pt]).then((res2) => {
            item.pt = res2[0];
          });
        }
        that.sucessLine = res;
        that.$store.commit("saveHomeSuccess", res);
      } else {
        that.sucessLine = that.homeSuccess;
      }
    },
    async getWhyList() {
      let that = this;
      if (that.homeWhyList.length == 0) {
        let res = await getAllData("syxzwm", 100);
        for (let item of res) {
          await that.getFileURL([item.tb]).then((res2) => {
            item.tb = res2[0];
          });
        }
        that.whySelect = res;
        that.$store.commit("saveHomeWhyList", res);
      } else {
        that.whySelect = that.homeWhyList;
      }
    },
    async getPartList(array) {
      let that = this;
      if (that.homePartList.length == 0) {
        that.partList = await that.getFileURL(array);
        that.$store.commit("saveHomePartList", that.partList);
      } else {
        that.partList = that.homePartList;
      }
    },
  },
  async created() {
    let that = this;
    that.runConcurrently([that.getHomeInfo(), that.getWhyList(), that.getSuccessStory()]);
  },
  async mounted() {
    // if (this.videoList) {
    //   this.playVideo();
    // }
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1800px) {
  .home-slogan {
    font-size: 45px;
  }
}

@media screen and (max-width: 1800px) {
  .home-slogan {
    font-size: 40px;
  }
}

@media screen and (max-width: 1366px) {
  .home-banner {
    .block .item {
      .txt {
        font-size: 22px;
      }

      .subtxt {
        font-size: 14px;
      }
    }
  }

  .home-box1 {
    .pro-info {
      .topic {
        font-size: 15px;
        margin-top: 30px;
      }

      .title {
        font-size: 25px;

        & > span {
          font-size: 27px;
        }
      }

      .jump {
        font-size: 15px;

        & > img {
          width: 12px;
          height: 12px;
          transform: rotate(180deg);
        }
      }
    }
  }

  .home-swiper {
    width: 62vw;
  }
}

@media screen and (min-width: 1366px) {
  .home-banner {
    .block .item {
      .txt {
        font-size: 25px;
      }

      .subtxt {
        font-size: 17px;
      }
    }
  }

  .home-box1 {
    .pro-info {
      .topic {
        font-size: 2.9cqw;
        margin-top: 45px;
      }

      .title {
        font-size: 4.7cqw;

        & > span {
          font-size: 5.1cqw;
        }
      }

      .jump {
        font-size: 2.7cqw;

        & > img {
          width: 2cqw;
          height: 2cqw;
          transform: rotate(180deg);
        }
      }
    }
  }

  .home-swiper {
    width: 61vw;
  }

  .swiper-box1 .home-swiper .words {
    .title {
      font-size: 26px;
    }

    .more {
      font-size: 18px;

      & > img {
        width: 14px;
        height: 14px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .home-box1 {
    padding: 0;
  }

  .home-title {
    font-size: 28px;
  }

  .home-partner .partner {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 1100px) {
  .home-box1 {
    padding: 0 5.625rem;
  }

  .home-title {
    font-size: 30px;
  }

  .home-partner .partner {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media screen and (max-width: 960px) {
  .home-banner {
    .block {
      display: none !important;
    }
  }

  .home-slogan {
    font-size: 5cqw;
    white-space: nowrap;
    top: 57%;
  }
  .home-digit2 .item {
    .digit2 {
      span:nth-child(1) {
        font-size: 46px;
      }

      .unit2 {
        font-size: 26px;
      }
    }

    .label2 {
      font-size: 18px;
    }
  }
  .home-box1 {
    padding: 0 90px;
  }

  .home-box1 .inner {
    gap: 15px;
    grid-template-columns: repeat(1, 1fr);

    .pro-info {
      container-type: inline-size;

      .topic {
        font-size: 3cqw;
        // margin-top: 55px;
      }

      .title {
        // font-size: 40px;
        font-size: 5cqw;

        span {
          font-size: 5cqw;
        }
      }

      .jump {
        // font-size: 20px;
        font-size: 2.5cqw;

        & > img {
          width: 2.1cqw;
          height: 2.1cqw;
        }
      }
    }
  }

  .home-title {
    margin: 70px auto;
  }

  .home-session {
    padding: 0 30px;
  }

  .long-swiper {
    display: none;
  }

  .short-swiper {
    display: block;
  }
}

@media screen and (min-width: 960px) {
  .home-digit2 .item {
    .digit2 {
      span:nth-child(1) {
        font-size: 46px;
      }

      .unit2 {
        font-size: 26px;
      }
    }

    .label2 {
      font-size: 22px;
    }
  }
  .home-title {
    margin: 87px auto;
  }

  .home-slogan {
    top: 50%;
  }

  .home-box1 .inner {
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  .home-session {
    padding: 0 90px;
  }

  .long-swiper {
    display: block;
  }

  .short-swiper {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .why-session {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .success-line1 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-bottom: 2px;
    .item1 {
      grid-area: 1 / 1 / 2 / 2;
    }
    .item2 {
      grid-area: 1 / 2 / 2 / 3;
    }
    .item3 {
      grid-area: 1 / 3 / 2 / 4;
    }
    .item4 {
      grid-area: 2 / 1 / 3 / 4;
    }
  }
  .success-line2 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    .item1 {
      grid-area: 1 / 1 / 2 / 4;
    }
    .item2 {
      grid-area: 1 / 4 / 2 / 7;
    }
    .item3 {
      grid-area: 2 / 1 / 3 / 3;
    }
    .item4 {
      grid-area: 2 / 3 / 3 / 5;
    }
    .item5 {
      grid-area: 2 / 5 / 3 / 7;
    }
  }
  .home-box1 {
    padding: 0 60px;
  }
  .home-digit2 .item {
    height: 200px;
    .digit2 {
      span:nth-child(1) {
        font-size: 38px;
      }

      .unit2 {
        font-size: 23px;
      }
    }

    .label2 {
      font-size: 15px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 36px;
    height: 36px;
    background: rgba(225, 231, 243, 0.29);
    border-radius: 50%;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 20px;
  }

  .home-partner .partner {
    gap: 15px;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 750px) {
  .why-session {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .home-digit2 {
    .item {
      height: 260px;
    }
  }
  .success-line1 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    margin-bottom: 5px;
    .item1 {
      grid-area: 1 / 1 / 2 / 2;
    }
    .item2 {
      grid-area: 1 / 2 / 2 / 3;
    }
    .item3 {
      grid-area: 1 / 3 / 2 / 4;
    }
    .item4 {
      grid-area: 1 / 4 / 2 / 6;
    }
  }
  .success-line2 {
    grid-template-columns: repeat(2, minmax(0, 1.5fr)) repeat(3, minmax(0, 1fr));
    .item1 {
      grid-area: 1 / 1 / 2 / 2;
    }
    .item2 {
      grid-area: 1 / 2 / 2 / 3;
    }
    .item3 {
      grid-area: 1 / 3 / 2 / 4;
    }
    .item4 {
      grid-area: 1 / 4 / 2 / 5;
    }
    .item5 {
      grid-area: 1 / 5 / 2 / 6;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 56px;
    height: 56px;
    background: rgba(225, 231, 243, 0.29);
    border-radius: 50%;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 25px;
  }

  .home-partner .partner {
    gap: 20px;
  }
}

@media screen and (min-width: 600px) {
  .why-content {
    grid-template-columns: repeat(3, 1fr);
    gap: 55px;
  }
  .success-line1,
  .success-line2 {
    gap: 5px;
    .img {
      height: 240px;
    }
    .tip {
      font-size: 18px;
      bottom: 6px;
      padding: 5px 10px;
    }
  }
}

@media screen and (max-width: 600px) {
  .success-line1,
  .success-line2 {
    gap: 2px;
    .tip {
      font-size: 14px;
      bottom: 3px;
      padding: 5px 5px 2px;
    }
    .img {
      height: 150px;
    }
  }
  .why-content {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
  .home-box1 {
    padding: 0 30px;
  }
  .tag {
    width: 39px;
    height: 19px;
    line-height: 19px;
    font-size: 13px;
  }

  .swiper-box1 .home-swiper {
    width: 78vw;
    .words {
      .title {
        font-size: 20px;
      }

      .more {
        font-size: 15px;

        & > img {
          width: 12px;
          height: 12px;
          margin-left: 5px;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .home-partner .partner {
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }

  .home-title {
    font-size: 24px;
  }

  .home-box1 .inner .pro-info {
    .topic {
      font-size: 18px;
    }

    .title {
      font-size: 25px;

      span {
        font-size: 25px;
      }
    }

    .jump {
      font-size: 16px;

      & > img {
        width: 12px;
        height: 12px;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .tag {
    width: 50px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
  }

  .swiper-box1 .home-swiper .words {
    .title {
      font-size: 26px;
    }

    .more {
      font-size: 18px;

      & > img {
        width: 13px;
        height: 13px;
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .home-digit2 {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    .item {
      height: 160px;
    }
  }
  .home-title {
    margin: 50px auto;
  }
  .home-digit2 .item {
    .digit2 {
      span:nth-child(1) {
        font-size: 33px;
      }

      .unit2 {
        font-size: 25px;
      }
    }

    .label2 {
      font-size: 14px;
    }
  }

  .home-partner .partner {
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 500px) {
  .home-digit2 {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
}
@media screen and (min-width: 450px) {
}

@media screen and (max-width: 450px) {
  .home-box1 {
    padding: 0;
  }
  .home-box1 .inner .pro-info {
    .topic {
      margin-top: 15px;
    }
  }
}

@keyframes toEnd {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

.home-banner {
  width: 100%;
  position: relative;
  container-type: inline-size;
  video {
    width: 100%;
    height: auto;
    max-height: 100vh;
    object-fit: cover;
  }

  .block {
    width: 100%;
    position: absolute;
    bottom: 8%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fefeff;
    container-type: inline-size;

    .item {
      & > span {
        display: block;
      }

      .txt,
      .subtxt {
        font-weight: 400;
        color: #fefeff;
      }

      .txt {
        font-family: "Alibaba PuHuiTi R", sans-serif;
        // font-size: 1.4cqw;
      }

      .subtxt {
        font-family: "Poppins-Light", sans-serif;
        // font-size: 1cqw;
        margin: 0.3125rem 0;
        cursor: pointer;

        & > img {
          width: 0.8cqw;
          height: 0.8cqw;
          transform: rotate(180deg);
          margin-left: 1.125rem;
        }
      }

      .line {
        display: block;
        width: 25cqw;
        height: 1px;
        background-color: #a0a0a0;
        margin-top: 1.5cqw;
        position: relative;
        .progress {
          //   position: absolute;
          //   left: 0;
          //   bottom: 0;
          //   width: 0%;
          //   height: 1px;
          //   background-color: #fff;
          //   animation-name: toEnd;
          //   animation-fill-mode: forwards;
          //   animation-duration: 9s;
          //   animation-timing-function: linear;
        }
      }
    }
  }
}

.home-slogan {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Alibaba PuHuiTi B", sans-serif;
  font-weight: 400;
  color: #fefeff;
  transition: all 0.4s ease;
}

.home-title {
  max-width: 1026px;
  position: relative;
  font-family: "Alibaba PuHuiTi B", sans-serif;
  color: #010c21;
  text-align: center;
  transition: all 0.5s ease;

  &::after {
    content: "";
    width: 31px;
    border: 2px solid var(--main-one-color);
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.home-box1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;

  .inner {
    width: 100%;
    max-width: 93.75rem;
    flex: 1;
    display: grid;

    .block-one {
      width: 100%;
      position: relative;
      container-type: inline-size;
      overflow: hidden;

      & > img {
        width: 100%;
        height: auto;
        transition: all 0.4s ease;

        &:hover {
          scale: 1.2;
        }
      }

      .pro-info {
        position: absolute;
        top: 0;
        width: 100%;
        height: 50%;
        text-align: center;

        .topic {
          color: rgba(1, 12, 33, 0.6);
          font-family: "Alibaba PuHuiTi R", sans-serif;
          transition: all 0.5s ease;
        }

        .title {
          position: relative;
          margin: 0.6cqh 0;
          display: inline-block;
          font-family: "Alibaba PuHuiTi L", sans-serif;

          & > span {
            font-family: "Poppins-SemiBold", sans-serif;
            margin: 0 5px;
            transition: all 0.5s ease;
          }

          .tag {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(110%, -40%);
            display: block;
            font-family: "Alibaba PuHuiTi R", sans-serif;
            text-align: center;
            background: rgba(230, 15, 15, 1);
            border-radius: 5px 2px 5px 2px;
            color: #ffffff;
            transition: all 0.5s ease;
          }
        }

        .pro-more {
          display: flex;
          align-items: center;
          justify-content: center;

          .jump {
            // font-size: 2.4cqw;
            font-family: "Alibaba PuHuiTi R", sans-serif;
            display: flex;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            color: #010c21;

            span {
              transition: all 0.5s ease;
            }

            & > img {
              // width: 1.8cqw;
              // height: 1.8cqw;
              // transform: rotate(180deg) translateY(-0.1cqw);
              margin-left: 0.5rem;
              transition: all 0.5s ease;
            }
          }

          .jump:nth-child(odd) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.home-session {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-pack {
  max-width: 93.75rem;
}

// 合作伙伴
.home-partner {
  flex: 1;
  text-align: center;
  margin-bottom: 120px;
  container-type: inline-size;

  .partner {
    display: grid;
    height: auto;

    .item {
      width: 100%;
      height: auto;

      & > img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      &:hover {
        transform: scale(1.15);
      }
    }
  }
}

.swiper-box1 {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.home-swiper {
  // min-width: 500px;
  max-width: 1500px;
  overflow: visible;
  --swiper-pagination-color: #fff;

  .swiper-slide-active {
    .words {
      display: block !important;
    }
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    .words {
      display: none;
    }
  }

  .item {
    position: relative;
    flex-shrink: 0;
    height: 100%;
    padding: 0 5px;

    & > img {
      width: 100%;
      height: auto;
    }

    .words {
      width: 100%;
      position: absolute;
      top: 8%;
      left: 0;
      text-align: center;
      container-type: size;

      .title {
        // font-size: 4cqw;
        font-weight: 500;
        color: #fff;
        font-family: "Alibaba PuHuiTi M", sans-serif;
        transition: all 0.5s ease;
      }

      .more {
        // font-size: 2cqw;
        color: #fff;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Alibaba PuHuiTi R", sans-serif;
        transition: all 0.5s ease;

        & > img {
          transform: rotate(180deg);
          transition: all 0.5s ease;
        }
      }
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  transition: all 0.5s ease;
}

.swiper-button-prev {
  left: 20px;
}

.swiper-button-next {
  right: 20px;
}

// 为什么选择我们
.why-session {
  width: 100%;
  background: #f6f9ff;
}
.why-title {
  font-family: "Alibaba PuHuiTi R", sans-serif;
  font-size: 30px;
  color: var(--main-bg-color);
  margin: 80px 0 50px;
  text-align: center;
}
.why-content {
  width: 100%;
  display: grid;
  .item {
    display: flex;
    .img {
      width: 40px;
      height: 40px;
      margin-right: 20px;
    }
  }
}
.why-subtitle {
  font-family: "Alibaba PuHuiTi M", sans-serif;
  font-size: 20px;
  color: var(--main-bg-color);
  line-height: 36px;
}
.why-pass {
  font-family: "Alibaba PuHuiTi R", sans-serif;
  font-size: 16px;
  color: var(--main-bg-color);
  line-height: 26px;
  opacity: 0.5;
  margin-top: 12px;
}
.quote {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.mailto {
  display: block;
  width: 160px;
  height: 40px;
  background: var(--main-one-color);
  border-radius: 10px;
  color: #fff;
  text-decoration: none;
  line-height: 40px;
  font-size: 18px;
  font-family: "Alibaba PuHuiTi M", sans-serif;
  text-align: center;
}
// 成功案例
.success-story {
  width: 100%;
}
.success-line1,
.success-line2 {
  width: 100%;
  display: grid;
  align-items: flex-start;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  .tip {
    width: 100%;
    display: block;
    position: absolute;
    text-align: center;
    font-family: "Alibaba PuHuiTi R", sans-serif;
    color: #ffffff;
    white-space: wrap;
    word-wrap: break-word;
    word-break: break-all;
  }
}
// 数字展示2
.home-digit2 {
  width: 100%;
  display: grid;
  margin: 60px 0 30px;
  .item {
    width: 100%;
    padding: 15px;
    color: #fff;
    .digit2 {
      width: 100%;
      font-family: "Alibaba PuHuiTi R", sans-serif;
    }
    .label2 {
      width: 100%;
      font-family: "Alibaba PuHuiTi R", sans-serif;
    }
  }
}
</style>
